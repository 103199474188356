import styled from '@emotion/styled';
import { darken } from 'polished';
interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  variant: 'primary' | 'cancel' | 'outline' | 'danger';
  isLoading?: boolean; // Added isLoading prop
}
const Btn = styled.button<{
  variant: 'primary' | 'cancel' | 'outline' | 'danger';
  isLoading?: boolean;
}>`
  display: flex;
  padding: 11px 46px;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  border-radius: 100px;
  border: 0px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  ${({
  variant
}) => variant === 'primary' && `
    color: #fff;
    background: var(--MedSpa, #101A4A);
  `}

  ${({
  variant
}) => variant === 'cancel' && `
    border: 1px solid rgba(153, 167, 174, 0.5);
    color: var(--Primary-Navy, #002334);
  `}

  ${({
  variant
}) => variant === 'outline' && `
    background: white;
    color: #3E3E3E;
    border-radius: 100px;
    border: 1px solid var(--MedSpa, #3E3E3E);

    &:hover {
      opacity: 0.8;
    }
  `}

 ${({
  variant
}) => variant === 'danger' && `
      color: #fff;
      border: 1px solid var(--Secondary-Red, #F45555);
      border-radius: 56px;
      background: var(--Secondary-Red, #F45555);


      &:hover {
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
      }
    `}

    &:disabled {
    cursor: not-allowed;

    ${({
  variant
}) => variant === 'primary' && `
       color: var(--Grey---600, var(--MedSpa, #777));
       background: var(--Grey---400, #CCD3D6);
    `}

    ${({
  variant
}) => variant === 'cancel' && `
      color: ${darken(0.1, '#002334')};
      border: 1px solid ${darken(0.1, 'rgba(153, 167, 174, 0.5)')};
    `}

    ${({
  variant
}) => variant === 'outline' && `
      color: ${darken(0.1, '#3E3E3E')};
      border: 1px solid ${darken(0.1, '#3E3E3E')};
    `}
  }

  ${({
  isLoading
}) => isLoading && `
    cursor: not-allowed;
    opacity: 0.7;
  `}
`;

// Spinner component
const Spinner = styled.div`
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top: 2px solid #fff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  flex-shrink: 0; // Prevent the spinner from shrinking
  margin-right: 8px; // Add some space between the spinner and the text

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  variant,
  isLoading,
  ...other
}) => {
  return <Btn variant={variant} isLoading={isLoading} {...other} onClick={isLoading ? undefined : onClick} disabled={isLoading || other.disabled} data-sentry-element="Btn" data-sentry-component="Button" data-sentry-source-file="Button.tsx">
      {isLoading ? <>
          <Spinner />
          <span>Loading</span>
        </> : children}
    </Btn>;
};
export default Button;
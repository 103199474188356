import styled from '@emotion/styled';
import { useState } from 'react';
import AdminLogin from '@/components/Login/AdminLogin';
import HIPAALogo from '@/components/Login/HIPAALogo';
import PatientLogin from '@/components/Login/PatientLogin';
import { auth } from '@/lib/auth';
import { SessionProvider } from 'next-auth/react';
const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 27px;
  background: linear-gradient(180deg, #ffffff 0%, #fff1ea 100%);
`;
const SignOnPathContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #777;
  text-align: center;
  font-family: 'GT Eesti Text';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (max-width: 480px) {
    display: none;
  }

  button {
    background: none;
    border: none;
    color: var(--Primary, #3a55de);
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
`;
const NoticesAndTermsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 32px;

  .links-list {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #777;
    text-align: right;
    font-family: 'GT Eesti Text';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%; /* 17.4px */

    a {
      color: inherit;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;
const HIPAAContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  max-width: 528px;
  width: 100%;
  padding: 0 44px;

  p {
    color: #777;
    text-align: center;
    font-family: 'GT Eesti Text';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%; /* 17.4px */
  }
`;

// TODO:
// - Connect links

export default function Login({
  session
}) {
  const [patient, setPatient] = useState(true);
  const changeUserSignIn = () => {
    setPatient(!patient);
  };
  return <SessionProvider session={session} data-sentry-element="SessionProvider" data-sentry-component="Login" data-sentry-source-file="login.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="login.tsx">
        {patient ? <>
            <PatientLogin />
            <SignOnPathContainer>
              Are you a Modern Halo Staff Member? <button onClick={changeUserSignIn}>Sign In here</button>
            </SignOnPathContainer>
          </> : <>
            <AdminLogin />
            <SignOnPathContainer>
              Are you a Modern Halo Patient? <button onClick={changeUserSignIn}>Sign In here</button>
            </SignOnPathContainer>
          </>}
        <NoticesAndTermsContainer data-sentry-element="NoticesAndTermsContainer" data-sentry-source-file="login.tsx">
          <div className="links-list">
            <a href="https://modernhalo.com/terms">Terms & Conditions</a>
            <span>|</span>
            <a href="https://modernhalo.com/privacy">Privacy Policy</a>
          </div>
          <HIPAAContainer data-sentry-element="HIPAAContainer" data-sentry-source-file="login.tsx">
            <HIPAALogo data-sentry-element="HIPAALogo" data-sentry-source-file="login.tsx" />
            <p>Our platform is HIPAA compliant and your data will not be stored or shared with anyone. </p>
          </HIPAAContainer>
        </NoticesAndTermsContainer>
      </Container>
    </SessionProvider>;
}
export async function getServerSideProps(ctx) {
  const session = await auth(ctx);
  return {
    props: {
      session
    }
  };
}
import { addMinutes, addWeeks, isBefore, isDate, isEqual, parseISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

/**
 * Parses an ISO 8601 formatted date string and converts it to a time in a specified time zone.
 *
 * @param time - The ISO 8601 formatted date string.
 * @param tz - The target time zone string (e.g., 'America/New_York', 'Europe/London').
 * @returns A Date object representing the time in the specified time zone.
 *
 * @example
 * const zonedTime = parseISOToZonedTime('2024-07-08T09:00:00Z', 'America/New_York');
 * console.log(zonedTime);
 */
export function parseISOToZonedTime(time: string, tz: string) {
  return toZonedTime(parseISO(time), tz);
}

/**
 * Generates an array of intervals of start times, starting at the start time.
 * The last option cannot exceed the end of the end time.
 *
 * @param startTime - The start time as a Date object.
 * @param endTime - The end time as a Date object.
 * @param interval - The interval in minutes (default is 15 minutes).
 * @returns An array of Date objects representing the start times of the intervals.
 */
export function generateIntervals(
  startTime: Date | string,
  endTime: Date | string,
  duration: number,
  interval: number = 15,
): Date[] {
  const intervals: Date[] = [];
  let current: Date | string = isDate(startTime) ? startTime : parseISO(startTime);
  if (current.getMinutes() % 15 !== 0) {
    current = addMinutes(current, 15 - (current.getMinutes() % 15));
    current.setSeconds(0);
    current.setMilliseconds(0);
  }

  while (isBefore(addMinutes(current, duration), endTime) || isEqual(addMinutes(current, duration), endTime)) {
    if (!isDate(current)) {
      intervals.push(parseISO(current));
    } else {
      intervals.push(current);
    }
    current = addMinutes(current, interval);
  }

  return intervals;
}

/**
 * Takes an array of Date objects and returns an object with 'am' and 'pm' keys.
 * Each key contains an array of times that are sorted and categorized appropriately.
 *
 * @param times - An array of Date objects.
 * @returns An object with 'am' and 'pm' keys, each containing an array of sorted Date objects.
 *
 * @example
 * const times = [new Date('2024-07-08T09:30:00'), new Date('2024-07-08T13:45:00'), new Date('2024-07-08T11:00:00')];
 * const result = categorizeAndSortTimesByDaySegment(times);
 * console.log(result);
 * // Output: { am: [Date, Date], pm: [Date] }
 */
export function categorizeAndSortTimesByDaySegment(times: Date[]): { am: Date[]; pm: Date[] } {
  const categorizedTimes = {
    am: [] as Date[],
    pm: [] as Date[],
  };

  times.forEach(time => {
    const hour = time.getHours(); // Use getHours() to get the hours in local time

    if (hour < 12) {
      categorizedTimes.am.push(time);
    } else {
      categorizedTimes.pm.push(time);
    }
  });

  categorizedTimes.am.sort((a, b) => a.getTime() - b.getTime());
  categorizedTimes.pm.sort((a, b) => a.getTime() - b.getTime());

  return categorizedTimes;
}

/**
 * Merges an array of objects with 'am' and 'pm' keys into a single object.
 * The resulting object will have 'am' and 'pm' arrays sorted with the earliest dates first.
 *
 * @param data - An array of objects with 'am' and 'pm' keys, each containing an array of Date objects.
 * @returns An object with 'am' and 'pm' keys, each containing a sorted array of Date objects.
 *
 * @example
 * const input = [
 *   { am: [new Date('2024-07-08T09:00:00'), new Date('2024-07-08T07:00:00')], pm: [new Date('2024-07-08T13:00:00')] },
 *   { am: [new Date('2024-07-08T08:00:00')], pm: [new Date('2024-07-08T16:00:00'), new Date('2024-07-08T14:00:00')] }
 * ];
 * const result = mergeAndSortTimes(input);
 * console.log(result);
 * // Output: { am: [Date, Date, Date], pm: [Date, Date, Date] }
 */
export function mergeAndSortTimes(data: { am: Date[]; pm: Date[] }[]): { am: Date[]; pm: Date[] } {
  const mergedTimes = {
    am: [] as Date[],
    pm: [] as Date[],
  };

  data.forEach(obj => {
    mergedTimes.am.push(...obj.am);
    mergedTimes.pm.push(...obj.pm);
  });

  mergedTimes.am.sort((a, b) => a.getTime() - b.getTime());
  mergedTimes.pm.sort((a, b) => a.getTime() - b.getTime());

  return mergedTimes;
}

/**
 * Computes the number of weeks from the startDate to the endDate.
 *
 * @param startTime - The start time as a Date object.
 * @param endTime - The end time as a Date object.
 * @returns A number indicating the number of weeks from the startDate to the endDate.
 */
export function computeNumberOfWeeks(startTime: Date | string, endTime: Date | string): number {
  let current: Date | string = isDate(startTime) ? startTime : parseISO(startTime);
  current = addWeeks(current, 1);
  let numberOfWeeks = 1;

  while (isBefore(current, endTime) || isEqual(current, endTime)) {
    numberOfWeeks++;
    current = addWeeks(current, 1);
  }

  return numberOfWeeks;
}

export const timezoneAbbreviations = {
  'America/New_York': 'ET',
  'America/Detroit': 'ET',
  'America/Kentucky/Louisville': 'ET',
  'America/Kentucky/Monticello': 'ET',
  'America/Indiana/Indianapolis': 'ET',
  'America/Indiana/Vincennes': 'ET',
  'America/Indiana/Winamac': 'ET',
  'America/Indiana/Marengo': 'ET',
  'America/Indiana/Petersburg': 'ET',
  'America/Indiana/Vevay': 'ET',
  'America/Chicago': 'CT',
  'America/Indiana/Tell_City': 'CT',
  'America/Indiana/Knox': 'CT',
  'America/Menominee': 'CT',
  'America/North_Dakota/Center': 'CT',
  'America/North_Dakota/New_Salem': 'CT',
  'America/North_Dakota/Beulah': 'CT',
  'America/Denver': 'MT',
  'America/Boise': 'MT',
  'America/Phoenix': 'MT', // Arizona does not observe DST
  'America/Los_Angeles': 'PT',
  'America/Anchorage': 'AKT',
  'America/Juneau': 'AKT',
  'America/Sitka': 'AKT',
  'America/Metlakatla': 'AKT',
  'America/Yakutat': 'AKT',
  'America/Nome': 'AKT',
  'America/Adak': 'HAT',
  'Pacific/Honolulu': 'HAT',
};

export const timezoneMapping: Record<string, string> = {
  AMERICA_NEW_YORK: 'America/New_York',
  AMERICA_CHICAGO: 'America/Chicago',
  AMERICA_DENVER: 'America/Denver',
  AMERICA_LOS_ANGELES: 'America/Los_Angeles',
  AMERICA_ANCHORAGE: 'America/Anchorage',
  AMERICA_ADAK: 'America/Adak',
  PACIFIC_HONOLULU: 'Pacific/Honolulu',
  AMERICA_PHOENIX: 'America/Phoenix',
  AMERICA_BOISE: 'America/Boise',
  AMERICA_INDIANA_INDIANAPOLIS: 'America/Indiana/Indianapolis',
  AMERICA_INDIANA_TELL_CITY: 'America/Indiana/Tell_City',
  AMERICA_INDIANA_KNOX: 'America/Indiana/Knox',
  AMERICA_INDIANA_WINAMAC: 'America/Indiana/Winamac',
  AMERICA_INDIANA_MARENGO: 'America/Indiana/Marengo',
  AMERICA_INDIANA_VEVAY: 'America/Indiana/Vevay',
  AMERICA_KENTUCKY_LOUISVILLE: 'America/Kentucky/Louisville',
  AMERICA_KENTUCKY_MONTICELLO: 'America/Kentucky/Monticello',
  AMERICA_DETROIT: 'America/Detroit',
  AMERICA_MENOMINEE: 'America/Menominee',
  AMERICA_ST_JOHNS: 'America/St_Johns',
  AMERICA_JUNEAU: 'America/Juneau',
  AMERICA_NOME: 'America/Nome',
  AMERICA_YAKUTAT: 'America/Yakutat',
  AMERICA_SITKA: 'America/Sitka',
};

/**
 * Inverse mapping of timezoneMapping, created once for efficiency.
 */
export const inverseTimezoneMapping: Record<string, string> = Object.fromEntries(
  Object.entries(timezoneMapping).map(([key, value]) => [value, key]),
);

/**
 * Converts a timezone string to its corresponding enum-like key.
 * @param timezone - The timezone string (e.g., 'America/New_York')
 * @returns The corresponding enum-like key (e.g., 'AMERICA_NEW_YORK') or the original string if not found
 */
export function getTimezoneKey(timezone: string): string {
  return inverseTimezoneMapping[timezone] || timezone;
}

/**
 * Converts a timezone identifier to its corresponding IANA timezone string.
 *
 * @param timezone - The timezone identifier. Can be either an IANA timezone string (e.g., 'America/New_York')
 *                   or an enum-like key (e.g., 'AMERICA_NEW_YORK').
 * @returns The IANA timezone string. If the input is already an IANA timezone string, it's returned as-is.
 *          If the input is an enum-like key, it's converted to the corresponding IANA timezone string.
 *          If the input doesn't match any known timezone, 'America/New_York' is returned as a default.
 *
 * @example
 * console.log(getTimezoneString('America/Los_Angeles')); // Output: 'America/Los_Angeles'
 * console.log(getTimezoneString('AMERICA_CHICAGO')); // Output: 'America/Chicago'
 * console.log(getTimezoneString('UNKNOWN_TIMEZONE')); // Output: 'America/New_York'
 */
export function getTimezoneString(timezone: string): string {
  if (timezone.includes('/')) {
    return timezone;
  }

  return timezoneMapping[timezone] || 'America/New_York';
}

import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;
const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
const SpinnerElement = styled.div`
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #101a4a; /* Brand color */
  border-radius: 50%;
  width: 100px; /* Adjust as needed */
  height: 100px; /* Adjust as needed */
  animation: ${spin} 2s linear infinite;
  flex-shrink: 0; /* Prevent the spinner from shrinking */
`;
const Spinner: React.FC = () => {
  return <SpinnerContainer data-sentry-element="SpinnerContainer" data-sentry-component="Spinner" data-sentry-source-file="Spinner.tsx">
      <SpinnerElement data-sentry-element="SpinnerElement" data-sentry-source-file="Spinner.tsx" />
    </SpinnerContainer>;
};
export default Spinner;
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(LocalizedFormat);
dayjs.extend(isBetween);
export const validateToUtc = timestamp => {
  const isValid = dayjs(timestamp).isValid();

  if (!timestamp || !isValid) {
    return null;
  }

  return dayjs(timestamp).utc();
};

export default dayjs;
